<template>
  <Sidebar ref="sidebar" :title="title">
    <div v-if="ready" class="grid grid-cols-6 gap-x-6 gap-y-16">
      <template v-if="type === 'people'">
        <div class="asset-infolist col-span-3" v-if="values.lastname || values.firstname || values.gender || values.birthdate">
          <h2 class="text-xl font-semibold text-prasset-green-500">
            Persoonsgegevens
          </h2>
          <div v-if="fullName" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Naam</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">{{ fullName }}</div>
          </div>
          <div v-if="values.gender" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Geslacht</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">{{ $tuf(values.gender) }}</div>
          </div>
          <div v-if="values.birthdate" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Geboortedatum</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">{{ values.birthdate }}</div>
          </div>
        </div>

        <div class="asset-infolist col-span-3" v-if="values.phone || values.email">
          <h2 class="text-xl font-semibold text-prasset-green-500">
            Contactgegevens
          </h2>
          <div v-if="values.phone" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Telefoon</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">{{ values.phone }}</div>
          </div>
          <div v-if="values.email" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">E-mail</div>
            <div class="text-sm text-prasset-grey-800 col-span-2"><a :href="`mailto:values.email`" class="text-prasset-green-500 underline">{{ values.email }}</a></div>
          </div>
        </div>

        <div class="asset-infolist col-span-3" v-if="values.street || values.housenumber || values.postalcode || values.city || values.country || values.municipality || values.province">
          <h2 class="text-xl font-semibold text-prasset-green-500">
            Adresgegevens
          </h2>
          <div v-if="values.street || values.housenumber || values.postalcode || values.city || values.country" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Adres</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              <div>
                <span v-if="values.street">{{ values.street }} </span>
                <span v-if="values.housenumber">{{ values.housenumber }}</span>
                <span v-if="values.housenumber_addition">{{ values.housenumber_addition }}</span>,
              </div>
              <div>
                <span v-if="values.postalcode">{{ values.postalcode }} </span>
                <span v-if="values.city">{{ values.city }}</span>
                <span v-if="values.country">, {{ values.country }} </span>
              </div>
            </div>
          </div>
          <div v-if="values.municipality" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Gemeente</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">{{ values.municipality }}</div>
          </div>
          <div v-if="values.province" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Provincie</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">{{ values.province }}</div>
          </div>
        </div>

        <div  class="asset-infolist col-span-6">
          <h2 class="text-xl font-semibold text-prasset-green-500">
            Woonvoorkeuren
          </h2>
          <Preferences @change="preferencedChanged" />
          <div v-if="groupedPreferences.asset" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Assets</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              <ol class="list-decimal list-inside">
                <li v-for="preference in groupedPreferences.asset" :key="preference.id">
                  {{ preference.preferable.name }}
                </li>
              </ol>
            </div>
          </div>
          <div v-if="groupedPreferences.project" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Projecten</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              <ol class="list-decimal list-inside">
                <li v-for="preference in groupedPreferences.project" :key="preference.id">
                  {{ preference.preferable.name }}
                </li>
              </ol>
            </div>
          </div>
          <div v-if="groupedPreferences.assettype" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Asset types</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              <ol class="list-decimal list-inside">
                <li v-for="preference in groupedPreferences.assettype" :key="preference.id">
                  {{ preference.preferable.name }}
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div  class="asset-infolist col-span-6">
          <h2 class="text-xl font-semibold text-prasset-green-500">
            Notities
          </h2>
          <Notes :setted-notes="values.notes" />
        </div>
      </template>
      <template v-else>
        <div class="asset-infolist">
          <div class="asset-infolist__item" v-for="(value, key) in values" :key="key">
            <div class="text-sm text-gray-600 col-span-1">
              {{ key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ') }}
            </div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ value }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </Sidebar>
</template>

<script>
import { toRefs, computed, reactive, onMounted } from '@vue/composition-api';
import Api from '@/providers/api';
import get from 'lodash/get';
import Preferences from '@/modules/crm/views/components/Preferences';
import Notes from '@/components/notes/Notes';
import Sidebar from '@/modules/core/views/components/Sidebar';

export default {
  components: {
    Notes,
    Preferences,
    Sidebar,
  },

  setup(props, { root }) {
    const { project_id, type, entry } = root.$route.params;

    const state = reactive({
      values: {},
      ready: false,
      loading: false,
    });

    const labels = {
      people: 'Persoon',
    };

    const endpoint = `projects/${project_id}/${type}/${entry}`;

    const hasValues = computed(() => {
      return Object.keys(state.values).length > 0;
    });

    function getValue(property, defaultValue, source) {
      return get(source || state.values, property, defaultValue);
    }

    function anyOf(...properties) {
      for (let i = 0; i < properties.length; i++) {
        if (get(state.values, properties[i])) {
          return true;
        }
      }
    }

    async function preferencedChanged() {
      const response = await Api().get(endpoint);
      state.values = response.data.data;
    }

    const fullName = computed(() => {
      return [
        get(state.values, 'firstname'),
        get(state.values, 'infix'),
        get(state.values, 'lastname'),
      ].join(' ');
    });

    const groupedPreferences = computed(() => {
      const preferences = get(state.values, 'preferences', []);
      const groups = {};

      preferences.map(preference => {
        groups[preference.preferable_type] = groups[preference.preferable_type] || [];
        groups[preference.preferable_type].push(preference);
      });

      return groups;
    });

    /**
     * Load form data.
     */
    async function load() {
      state.loading = true;

      const response = await Api().get(endpoint);

      state.values = response.data.data,

      state.loading = false;
      state.ready = true;
    }

    onMounted(async () => {
      load();
    });

    return {
      ...toRefs(state),
      hasValues,
      getValue,
      anyOf,
      labels,
      type,
      title: labels[type],
      preferencedChanged,
      fullName,
      groupedPreferences,
    };
  },
};
</script>

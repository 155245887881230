import { toRefs, reactive } from '@vue/composition-api';
import Api from '@/providers/api';

const state = reactive({
  notes: [],
});

const addNote = (type, entry) => {
  state.notes.push({
    id: null,
    note: '',
    notable_type: type,
    notable_id: entry,
  });
};

const setNotes = (notes) => {
  state.notes = notes;
};

const getNote = async (project_id, id) => {
  const response = await Api().get(`projects/${project_id}/notes/${id}`);

  return response.data;
};

const createNote = async (project_id, data) => {
  const response = await Api().post(`projects/${project_id}/notes`, {
    ...data,
  });

  return { ...response.data.data };
};


const updateNote = async (project_id, id, note) => {
  const response = await Api().patch(`projects/${project_id}/notes/${id}`, {
    note: note,
  });

  return { ...response.data.data };
};

const deleteNote = async (project_id, note_id) => {
  const idx = state.notes.findIndex(n => n.id === note_id);
  state.notes.splice(idx, 1);

  const response = await Api().delete(`projects/${project_id}/notes/${note_id}`);
  return { ...response.data.data };
};

export default function useNotes() {

  return {
    ...toRefs(state),
    getNote,
    addNote,
    setNotes,
    createNote,
    updateNote,
    deleteNote,
  };
}

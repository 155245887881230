import { toRefs, reactive } from '@vue/composition-api';
import Api from '@/providers/api';

const state = reactive({
  users: [],
});

const getUsers = async () => {
  const response = await Api().get(`users`);

  state.users = response.data.data;
};

export default function useUsers() {
  return {
    ...toRefs(state),
    getUsers,
  };
}

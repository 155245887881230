<template>
  <div class="flex flex-row w-full">
    <img class="min-w-8 h-8 rounded-full" :src="`https://gravatar.com/avatar/${note.author.email_md5}?s=30&d=mp`" />
    <div class="flex flex-col ml-3 w-full">
      <div class="flex flex-row mb-2 justify-between h-8">
        <p class="text-gray-500 font-medium text-sm mr-6">
          {{ note.author.name }} • {{ formatDate(note.created_at) }}
        </p>
        <div v-if="!edit && me.id === note.author.uuid">
          <button type="button" @click="edit = !edit"><i class="ri-edit-2-line mr-4"></i></button>
          <button type="button" @click="remove(note)"><i class="ri-delete-bin-line"></i></button>
        </div>
      </div>
      <p class="text-base mr-12" v-if="!edit">
        {{ formattedContent }}
      </p>
      <Mentionable
        v-if="edit"
        class="w-full"
        :keys="['@']"
        :items="formattedUsers"
      >
        <textarea
          class="p-2 w-full focus:outline-none min-h-40  bg-white placeholder-gray-600 font-base font-normal rounded border"
          v-model="editableContent"
        />
      </Mentionable>
      <div class="flex justify-end mt-2" v-if="edit">
        <button type="button" class="button button--small mr-2" @click="closeEdit()">
          Annuleren
        </button>
        <button type="button" class="button button--opague button--small" @click="update()">
          <i class="ri-save-line"></i><span class="ml-2">Wijzigen</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api';
import { Mentionable } from 'vue-mention';

import EventBus from '@/eventbus';
import useUsers from '@/compositions/useUsers';
import useNotes from '@/compositions/useNotes';
import useAuth from '@/compositions/useAuth';

export default {
  name: 'Notable',
  components: {
    Mentionable,
  },
  props: {
    note: {
      type: Object,
      default: null,
    },
  },
  setup(props, { root }) {
    const { users } = useUsers();
    const { deleteNote, updateNote } = useNotes();
    const { me } = useAuth();

    const edit = ref(false);
    const onSave = ref(false);

    const editableContent = ref(props.note.note);

    const closeEdit = () => {
      edit.value = false;
      editableContent.value = props.note.note;
    };

    const update = () => {
      onSave.value = true;
      let newNote = editableContent.value;
      updateNote(root.$route.params.project_id, props.note.id, newNote);

      root.$notify({
        type: 'success',
        title: 'Succes',
        text: 'Notitie is gewijzigd',
      });

      edit.value = false;
      onSave.value = false;
    };

    const remove = (note) => {
      EventBus.$emit('confirm:open', {
        title: 'Let op',
        main: `Weet je zeker dat je deze notitie wilt verwijderen?`,
        confirmButton: 'Bevestigen',
        cancelButton: 'Annuleren',
        onConfirm() {
          deleteNote(root.$route.params.project_id, note.id);
        },
      });
    };

    const formatDate = (d) => {
      let date = new Date(d);
      let day = new Intl.DateTimeFormat('nl', { day: '2-digit' }).format(date);
      let month = new Intl.DateTimeFormat('nl', { month: 'short' }).format(date);
      let year = new Intl.DateTimeFormat('nl', { year: 'numeric' }).format(date);
      let time = new Intl.DateTimeFormat('default', { hour: 'numeric', minute: 'numeric' }).format(date);
      return `${day} ${month} ${year} ${time}`;
    };

    const formattedUsers = computed(() => {
      return users.value.map(user => ({
        value: `[${user.name}](id:${user.id})`,
        label: user.name,
      }));
    });

    const formattedContent = computed(() => {
      return editableContent.value.replace(/@\[([^\]]+)\]\(([^\)]*)\)/ig, '$1'); // eslint-disable-line
    });

    return {
      closeEdit,
      editableContent,
      formattedContent,
      formattedUsers,
      formatDate,
      edit,
      update,
      me,
      remove,
    };
  },
};
</script>

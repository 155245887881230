<template>
  <section class="grid grid-cols-1 gap-3">
    <div
      class="p-4 flex flex-col w-full bg-white placeholder-gray-600 font-base font-normal rounded border col-span-1"
      v-for="(note, idx) in notes"
      :key="idx"
    >
      <Notable :note="note" />
    </div>
  </section>
</template>

<script>
import Notable from './Notable';
import useNotes from '@/compositions/useNotes';

export default {
  name: 'Comments',
  components: {
    Notable,
  },
  setup() {
    const { updateNote, deleteNote, notes  } = useNotes();

    return {
      notes,
      deleteNote,
      updateNote,
    };
  },
};
</script>

<template>
  <Portal to="crudsidebar">
    <Transition name="sidebar">
      <aside class="crud-sidebar crud-sidebar--next">
        <div @click="close()" class="crud-sidebar__closer" />
        <div class="crud-sidebar__container">
          <header class="crud-sidebar__header">
            <div class="crud-sidebar__inner flex justify-between items-center h-full">
              <h2 class="title">{{ title }}</h2>
              <button @click="close()" class="crud-sidebar__close focus:outline-none">&times;</button>
            </div>
          </header>
          <main class="crud-sidebar__content">
            <div class="crud-sidebar__inner">
              <slot />
            </div>
          </main>
        </div>
      </aside>
    </Transition>
  </Portal>
</template>

<script>
import { Portal } from 'portal-vue';
import EventBus from '@/eventbus';

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
  },

  components: {
    Portal,
  },

  setup(props, context) {
    const parentRouteName = context.root.$route.matched[
      context.root.$route.matched.length - 2
    ].name;

    function navigateBack() {
      context.root.$router.push({
        name: parentRouteName,
        query: context.root.$route.query, // remain page and filters from crudtablegenerator.
      }).catch(() => {
        /* silence NavigationDuplicated error */
      });
    }

    function close() {
      if (context.parent.changed) {
        EventBus.$emit('confirm:open', {
          title: context.root.$tuf('notice'),
          main: context.root.$tuf('are_you_sure_you_want_to_leave_without_saving'),
          confirmButton: context.root.$tuf('confirm'),
          cancelButton: context.root.$tuf('cancel'),
          onConfirm() {
            context.emit('close');
            navigateBack();
          },
        });
      } else {
        navigateBack();
        context.emit('close');
      }
    }

    return {
      close,
    };
  },
};
</script>

<style>
.sidebar-enter-active, .sidebar-leave-active {
  transition: transform 500ms ease-in-out;
  transform: translate3d(0%, 0, 0);
}
.sidebar-enter, .sidebar-leave-to {
  transform: translate3d(100%, 0, 0);
}
</style>

<template>
  <Sidebar ref="sidebar" :title="title">
    <div v-if="ready" class="grid grid-cols-6 gap-x-6 gap-y-16">
      <template v-if="type === 'forms'">
        <div class="asset-infolist col-span-6" v-if="values.name">
          <div v-if="getValue('name')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Naam</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">{{ getValue('name') }}</div>
          </div>
          <div v-if="getValue('fields')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Beschikbare velden</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              <div v-for="field in getValue('fields')" :key="field.id">
                {{ field.label }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="type === 'formsubmissions'">
        <div class="asset-infolist col-span-6">
          <div v-if="getValue('form')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Formulier</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">{{ getValue('form.name') }}</div>
          </div>
          <div v-for="value in getValue('values')" :key="value.id" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">{{ getValue('field.label', null, value) }}</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ getValue('value', '-', value) }}
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="asset-infolist col-span-6">
          <div class="asset-infolist__item" v-for="(value, key) in values" :key="key">
            <div class="text-sm text-gray-600 col-span-1">
              {{ key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ') }}
            </div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ value }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </Sidebar>
</template>

<script>
import { toRefs, computed, reactive, onMounted } from '@vue/composition-api';
import Api from '@/providers/api';
import get from 'lodash/get';
import Sidebar from '@/modules/core/views/components/Sidebar';

export default {
  name: 'PimView',

  components: {
    Sidebar,
  },

  setup(props, { root }) {
    const { project_id, type, entry } = root.$route.params;

    const state = reactive({
      values: {},
      ready: false,
      loading: false,
    });

    const labels = {
      forms: 'Formulier',
      formnotifications: 'Notificatie',
      formsubmissions: 'Inzending',
    };

    const endpoint = `projects/${project_id}/${type}/${entry}`;

    const hasValues = computed(() => {
      return Object.keys(state.values).length > 0;
    });

    function getValue(property, defaultValue, source) {
      return get(source || state.values, property, defaultValue);
    }

    function anyOf(...properties) {
      for (let i = 0; i < properties.length; i++) {
        if (get(state.values, properties[i])) {
          return true;
        }
      }
    }

    /**
     * Load form data.
     */
    async function load() {
      state.loading = true;

      const response = await Api().get(endpoint);

      state.values = response.data.data,

      state.loading = false;
      state.ready = true;
    }

    onMounted(async () => {
      load();
    });

    return {
      ...toRefs(state),
      hasValues,
      getValue,
      anyOf,
      labels,
      type,
      title: labels[type],
    };
  },
};
</script>

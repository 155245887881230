<template>
  <form method="post" @submit.prevent="save" class="grid grid-cols-3 gap-1">
    <div><SelectField v-model="selectedProject" :endpoint="projectsEndpoint" /></div>
    <div><SelectField v-model="selectedAsset" :options="assets" :disabled="loading" /></div>
    <div><button type="submit" class="button button--opague button--small" :disabled="loading">Toevoegen</button></div>
  </form>
</template>

<script>
import { toRefs, reactive, watch } from '@vue/composition-api';
import SelectField from '@/components/field/Select';
import Api from '@/providers/api';

export default {
  components: {
    SelectField,
  },

  props: {

  },

  setup(props, { root, emit }) {
    const { project_id, entry } = root.$route.params;

    const state = reactive({
      selectedProject: project_id,
      selectedAsset: null,
      assets: {},
      loading: true,
    });

    watch(() => state.selectedProject, () => {
      loadAssets();
    }, { immediate: true });

    async function loadAssets() {
      state.loading = true;

      const response = await Api().get(`projects/${state.selectedProject}/assets`);

      const options = {};
      response.data.data.forEach(asset => {
        options[asset.id] = asset.name;
      });

      state.assets = options;
      state.loading = false;
    }

    async function save() {
      let objectId = state.selectedProject;
      let objectType = 'project';

      if (state.selectedAsset) {
        objectId = state.selectedAsset;
        objectType = 'asset';
      }

      await Api().post(`projects/${project_id}/preferences`, {
        person_id: entry,
        object_id: objectId,
        object_type: objectType,
      });

      emit('change');
    }

    return {
      ...toRefs(state),
      save,
      projectsEndpoint: `projects`,
    };
  },
};
</script>

<template>
  <div class="grid grid-cols-1 gap-6">

    <Comments />

    <section class="flex flex-col w-full  bg-white placeholder-gray-600 font-base font-normal rounded border">
      <div class="flex flex-row p-3 items-center border-b placeholder-gray-600">
        <img class="w-8 h-8 rounded-full" :src="`https://gravatar.com/avatar/${me.email_md5}?s=30&d=mp`" />
        <p class="text-gray-500 font-medium text-sm ml-3">{{ me.name }}</p>
      </div>

      <Mentionable
        class="w-full"
        :keys="['@']"
        :items="formattedUsers"
      >
        <textarea
          class="py-2 px-4 w-full focus:outline-none min-h-40"
          v-model="note.note"
        />
      </Mentionable>

      <div class="flex flex-row p-3 justify-end border-t placeholder-gray-600 items-center">
        <button
          type="button"
          class="button button--opague button--small"
          :class="{ 'opacity-50' : note.note.length === 0 }"
          :disabled="note.note.length === 0"
          @click="save()"
        >
          <i class="ri-save-line"></i><span class="ml-2">Toevoegen</span>
        </button>
      </div>
    </section>
  </div>
</template>


<script>
import { Mentionable } from 'vue-mention';
import { computed, reactive } from '@vue/composition-api';
import { generateUUID } from '@/providers/helpers';

import Comments from './Comments';

import useUsers from '@/compositions/useUsers';
import useNotes from '@/compositions/useNotes';
import useAuth from '@/compositions/useAuth';

export default {
  name: 'Notes',

  components: {
    Comments,
    Mentionable,
  },

  props: {
    settedNotes: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  setup(props, { root }) {

    const { me } = useAuth();
    const { getUsers, users } = useUsers();
    const { createNote, setNotes, notes } = useNotes();

    const note = reactive({
      id: null,
      note: '',
      notable_type: root.$route.params.type,
      notable_id: root.$route.params.entry,
    });

    getUsers();
    setNotes(props.settedNotes);


    const formattedUsers = computed(() =>  {
      return users.value.map(user => ({
        value: `[${user.name}](id:${user.id})`,
        label: user.name,
      }));
    });

    const save = async () => {
      note.id = generateUUID();

      const newNote = await createNote(root.$route.params.project_id, note);

      notes.value.push(newNote);


      note.id = null;
      note.note = '';

      root.$notify({
        type: 'success',
        title: 'Succes',
        text: 'Notitie is toegevoegd',
      });
    };

    return {
      me,
      save,
      note,
      formattedUsers,
    };
  },
};
</script>

<style lang="scss">
.popover {
  position: relative;
  z-index: 99;
}

.mention-item {
  padding: .5rem .75rem;
  z-index: 90;
  position: relative;
  background-color: #FFF;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.mention-selected {
  background-color: #f7fafc;
  cursor: pointer;
}
</style>

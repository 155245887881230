<template>
  <Sidebar ref="sidebar" :title="title">
    <div v-if="ready" class="grid grid-cols-6 gap-x-6 gap-y-16">
      <template v-if="type === 'assets'">

        <div class="asset-infolist col-span-6">
          <h2 class="text-xl font-semibold text-prasset-green-500">
            Asset
          </h2>
          <div class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Naam</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ getValue('name', '-') }}
            </div>
          </div>
          <div v-if="getValue('status')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Status</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{
                getValueDict('status', {
                  AVAILABLE: "Beschikbaar",
                  UNDER_OFFER: "Onder optie",
                  UNDER_OPTION: "In optie",
                  WITH_RESERVATION: "Gereserveerd",
                  SOLD: "Verkocht",
                  RENTED: "Verhuurd"
                })
              }}
            </div>
          </div>
          <div v-if="getValue('assettype')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Type</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ getValue('assettype.name') }}
            </div>
          </div>
          <div v-if="getValue('description')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Omschrijving</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ getValue('description') }}
            </div>
          </div>
        </div>

        <div v-if="anyOf('price', 'price_unit', 'price_type')" class="asset-infolist col-span-3">
          <h2 class="text-xl font-semibold text-prasset-green-500">
            Prijs
          </h2>
          <div v-if="getValue('price') !== undefined" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Prijs</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ formatPrice(getValue('price')) }}
            </div>
          </div>
          <div v-if="getValue('price_unit')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Prijs unit</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ getValue('price_unit') }}
            </div>
          </div>
          <div v-if="getValue('price_type')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Prijs type</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ getValue('price_type') }}
            </div>
          </div>
        </div>

        <div v-if="anyOf('asset_surface', 'asset_volume', 'lot_surface', 'property_valuation_value', 'property_valuation_date')" class="asset-infolist col-span-3">
          <h2 class="text-xl font-semibold text-prasset-green-500">
            Eigenschappen
          </h2>
          <div v-if="getValue('asset_surface')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Oppervlakte</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ getValue('asset_surface') }} m²
            </div>
          </div>
          <div v-if="getValue('asset_volume')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Inhoud</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ getValue('asset_volume') }} &#13221;
            </div>
          </div>
          <div v-if="getValue('lot_surface')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Kaveloppervlakte</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ getValue('lot_surface') }}
            </div>
          </div>
          <div v-if="getValue('property_valuation_value')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">WOZ waarde</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ formatPrice(getValue('property_valuation_value')) }}
            </div>
          </div>
          <div v-if="getValue('property_valuation_date')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">WOZ datum</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ getValue('property_valuation_date') }}
            </div>
          </div>
        </div>

        <div v-if="anyOf('appraisal_value', 'appraisal_date')" class="asset-infolist col-span-3">
          <h2 class="text-xl font-semibold text-prasset-green-500">
            Taxatiewaarde
          </h2>
          <div v-if="getValue('appraisal_value')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Waarde</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ formatPrice(getValue('appraisal_value')) }}
            </div>
          </div>
          <div v-if="getValue('appraisal_date')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Datum</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ getValue('appraisal_date') }}
            </div>
          </div>
        </div>

        <div v-if="anyOf('contract_type', 'contract_payment_frequency')" class="asset-infolist col-span-3">
          <h2 class="text-xl font-semibold text-prasset-green-500">
            Contract
          </h2>
          <div v-if="getValue('contract_type')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Contract type</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{
                getValueDict('contract_type', {
                  RENT: "Verhuur",
                  SALE: "Verkoop"
                })
              }}
            </div>
          </div>
          <div v-if="getValue('contract_payment_frequency')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Betaling frequentie</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ getValue('contract_payment_frequency') }}
            </div>
          </div>
        </div>

        <div class="asset-infolist col-span-3" v-if="getValue('address')">
          <h2 class="text-xl font-semibold text-prasset-green-500">
            Adresgegevens
          </h2>
          <div v-if="anyOf('address.street', 'address.housenumber', 'address.postalcode', 'address.city', 'address.country')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Adres</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              <div>
                <span v-if="getValue('address.street')">{{ getValue('address.street') }} </span>
                <span v-if="getValue('address.housenumber')">{{ getValue('address.housenumber') }}</span>
                <span v-if="getValue('address.housenumber_addition')">{{ getValue('address.housenumber_addition') }}</span>,
              </div>
              <div>
                <span v-if="getValue('address.postalcode')">{{ getValue('address.postalcode') }} </span>
                <span v-if="getValue('address.city')">{{ getValue('address.city') }}</span>
                <span v-if="getValue('address.country')">, {{ getValue('address.country') }} </span>
              </div>
            </div>
          </div>
          <div v-if="getValue('address.municipality')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Gemeente</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">{{ getValue('address.municipality') }}</div>
          </div>
          <div v-if="getValue('address.province')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Provincie</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">{{ getValue('address.province') }}</div>
          </div>
        </div>

        <div v-if="getValue('media') && getValue('media').length > 0" class="asset-infolist col-span-3">
          <h2 class="text-xl font-semibold text-prasset-green-500">
            Afbeeldingen
          </h2>
          <div class="grid grid-cols-3 gap-4">
            <figure v-for="(value, key) in getValue('media')" :key="key" class="relative flex justify-center items-center p-2 bg-white shadow">
              <img loading="lazy" class="max-w-full" :src="value.urls.original" />
            </figure>
          </div>
        </div>
      </template>

      <template v-if="type === 'assetgroups'">
        <div class="asset-infolist col-span-6">
          <h2 class="text-xl font-semibold text-prasset-green-500">
            Asset groep
          </h2>
          <div class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Naam</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ getValue('name', '-') }}
            </div>
          </div>
          <div v-if="getValue('description')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Omschrijving</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ getValue('description') }}
            </div>
          </div>
          <div v-if="getValue('children')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Binnen deze groep</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              <div v-for="child in getValue('children')" :key="child.id">
                {{ getValue('name', null, child) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="type === 'assettypes'">
        <div class="asset-infolist col-span-6">
          <h2 class="text-xl font-semibold text-prasset-green-500">
            Asset type
          </h2>
          <div class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Naam</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ getValue('name', '-') }}
            </div>
          </div>
          <div v-if="getValue('description')" class="asset-infolist__item">
            <div class="text-sm text-gray-600 col-span-1">Omschrijving</div>
            <div class="text-sm text-prasset-grey-800 col-span-2">
              {{ getValue('description') }}
            </div>
          </div>
        </div>
        <div v-if="getValue('media') && getValue('media').length > 0" class="asset-infolist">
          <h2 class="text-xl font-semibold text-prasset-green-500">
            Afbeeldingen
          </h2>
          <div class="grid grid-cols-3 gap-4">
            <figure v-for="(value, key) in getValue('media')" :key="key" class="relative flex justify-center items-center p-2 bg-white shadow">
              <img loading="lazy" class="max-w-full" :src="value.urls.original" />
            </figure>
          </div>
        </div>
      </template>

      <div class="asset-infolist col-span-6">
        <h2 class="text-xl font-semibold text-prasset-green-500">
          Notities
        </h2>
        <Notes :setted-notes="values.notes" />
      </div>
    </div>
  </Sidebar>
</template>

<script>
import { toRefs, computed, reactive, onMounted } from '@vue/composition-api';
import { formatPrice } from '@/providers/helpers';
import Api from '@/providers/api';
import get from 'lodash/get';
import Notes from '@/components/notes/Notes';
import Sidebar from '@/modules/core/views/components/Sidebar';

export default {
  name: 'PimView',

  components: {
    Notes,
    Sidebar,
  },

  setup(props, { root }) {
    const { project_id, type, entry } = root.$route.params;

    const state = reactive({
      values: {},
      ready: false,
      loading: false,
    });

    const labels = {
      assets: 'Asset',
      assetgroups: 'Categorie',
      assettypes: 'Asset type',
    };

    const endpoint = `projects/${project_id}/${type}/${entry}`;

    const hasValues = computed(() => {
      return Object.keys(state.values).length > 0;
    });

    function getValue(property, defaultValue, source) {
      return get(source || state.values, property, defaultValue);
    }

    function getValueDict(property, dict, defaultValue, source) {
      let value = getValue(property, defaultValue, source);
      return get(dict, value, defaultValue);
    }

    function anyOf(...properties) {
      for (let i = 0; i < properties.length; i++) {
        if (get(state.values, properties[i])) {
          return true;
        }
      }
    }

    /**
     * Load form data.
     */
    async function load() {
      state.loading = true;

      const response = await Api().get(endpoint);

      state.values = response.data.data,

      state.loading = false;
      state.ready = true;
    }

    onMounted(async () => {
      load();
    });

    return {
      ...toRefs(state),
      hasValues,
      getValueDict,
      getValue,
      anyOf,
      labels,
      type,
      title: labels[type],
      formatPrice,
    };
  },
};
</script>

<template>
  <Sidebar ref="sidebar" :title="title">
      <div class="asset-infolist">
        <div class="asset-infolist__item" v-for="(value, key) in values" :key="key">
          <div class="text-sm text-gray-600 col-span-1">
            {{ key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ') }}
          </div>
          <div class="text-sm text-prasset-grey-800 col-span-2">
            {{ value }}
          </div>
        </div>
      </div>
  </Sidebar>
</template>

<script>
import { toRefs, computed, reactive, onMounted } from '@vue/composition-api';
import Api from '@/providers/api';
import get from 'lodash/get';
import Sidebar from '@/modules/core/views/components/Sidebar';

export default {
  name: 'SettingsView',

  components: {
    Sidebar,
  },

  setup(props, { root }) {
    const { type, entry } = root.$route.params;

    const state = reactive({
      values: {},
      ready: false,
      loading: false,
    });

    const labels = {
      projects: 'Project',
      users: 'Gebruiker',
    };

    const endpoint = `${type}/${entry}`;

    const hasValues = computed(() => {
      return Object.keys(state.values).length > 0;
    });

    function getValue(property, defaultValue, source) {
      return get(source || state.values, property, defaultValue);
    }

    function anyOf(...properties) {
      for (let i = 0; i < properties.length; i++) {
        if (get(this.values, properties[i])) {
          return true;
        }
      }
    }

    /**
     * Load form data.
     */
    async function load() {
      state.loading = true;

      const response = await Api().get(endpoint);

      state.values = response.data.data,

      state.loading = false;
      state.ready = true;
    }

    onMounted(async () => {
      load();
    });

    return {
      ...toRefs(state),
      hasValues,
      getValue,
      anyOf,
      labels,
      type,
      title: labels[type],
    };
  },
};
</script>
